import './app.css'
import Home from './home'
import Header from './header'
import WebcamCapture from './webcam-capture'
import { BrowserRouter, Routes, Route } from "react-router-dom"

function App() {
  return (
    <div className="app">
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/home" element={ <Home/> } />
          <Route path="/demo" element={ <WebcamCapture/> } />
        </Routes>
      </BrowserRouter>

    </div>
  );
}

export default App
