import { Link } from "react-router-dom"
import logo from '../../images/2vatar.png'
import './index.css'
import { useEffect } from 'react';
import { useNavigate } from "react-router-dom"

const Header = () => {
  // TODO: This might not be the best solution.
  // Find a better way.
  const navigate = useNavigate()
  useEffect(() => {
    navigate("/home")
  }, [])

  return (
    <div className="app-header">
      <Link to='/home'>
        {/* TODO: Make sure to change the logo, as it's not bought */}
        <img src={logo} className="app-logo" alt="logo" />
      </Link>
      <div className="menu-container">
        <Link className="menu-text" to='/home'>
          Home
        </Link>
        <Link className="menu-text" to='/demo'>
          Demo
        </Link>
      </div>
    </div>
  );
};

export default Header;