import { Link } from 'react-router-dom'
import { useState, useRef, useEffect } from 'react'
import { avatarApiUrl } from '../utils/constants'
import { validateEmail } from '../utils/utils'
import HashLoader from 'react-spinners/HashLoader'
import Webcam from "react-webcam";
import './index.css'

const captureImageStates = {
  startDemo: 0,
  takeImages: 1,
  uploadImages: 2,
  uploadSuccess: 3,
}

const WebcamCapture = () => {
  const webcamRef = useRef(null)

  const [capturedImages, setCapturedImages] = useState<string[]>([])
  const [currentState, setCurrentState] = useState<number>(captureImageStates.startDemo)
  const [userEmail, setUserEmail] = useState<string>('')
  const [invalidEmail, setInvalidEmail] = useState<boolean>(false)
  const [uploadingImages, setUploadingImages] = useState<boolean>(false)

  const startWebcam = async () => {
    if (validateEmail(userEmail)) {
      setCurrentState(captureImageStates.takeImages)
    } else {
      setInvalidEmail(true)
    }
  }

  const captureImage = () => {
    // TODO: ADD A SCORE TO EACH IMAGE TAKEN, BASED ON HOW GOOD IT IS
    // TODO: ADD A SCORE TO EACH IMAGE TAKEN, BASED ON HOW GOOD IT IS
    // TODO: ADD A SCORE TO EACH IMAGE TAKEN, BASED ON HOW GOOD IT IS
    if (webcamRef?.current != null) {
      const current = webcamRef?.current as Webcam
      const imageDataUrl = current.getScreenshot()

      if (imageDataUrl) {
        const newCapturedImages = [...capturedImages, ...[imageDataUrl]]
        // Set the captured image
        setCapturedImages(newCapturedImages)

        if (newCapturedImages.length === 6) {
          setCurrentState(captureImageStates.uploadImages)
        }
      }
    }
  }

  const resetState = () => {
    setCapturedImages([]) // Reset captured images
    setCurrentState(captureImageStates.startDemo)
  }

  // Function to upload the images
  const uploadImages = async () => {
    setUploadingImages(true)
    try {
      await fetch(`${avatarApiUrl}/api/upload-input-images`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: userEmail,
          images: capturedImages,
        })
      })
      setCurrentState(captureImageStates.uploadSuccess)
    } catch (error) {
      alert('Error uploading the images.')
    }

    setUploadingImages(false)
  }

  return (
    <div className="main-container">
      {currentState === captureImageStates.startDemo && (
        <div className="base-container start-demo-container">
          <div className="start-demo-description">
            To start the demo please enter your email.
          </div>
          <input
            className="email-input-box"
            name="email"
            placeholder='Email'
            value={userEmail}
            onChange={e => {
              setUserEmail(e.target.value)
              setInvalidEmail(false)
            }}
          />
          <div className="invalid-email-container">
            {invalidEmail ? 'Please enter a valid email.' : ''}
          </div>
          <button className="twovatar-button" onClick={startWebcam}>Take images</button>
        </div>
      )}

      {currentState === captureImageStates.takeImages && (
        <div className="base-container webcam-container">
          <Webcam
            audio={false}
            screenshotFormat="image/jpeg"
            ref={webcamRef}
          ></Webcam>
          <button className="twovatar-button capture-button" onClick={captureImage}>{`Capture Image (${capturedImages.length}/5)`}</button>
        </div>
      )}

      {currentState === captureImageStates.uploadImages && (
        <div>
          {uploadingImages === true ? (
              <div className="uploading-images-container">
                <HashLoader
                  color={'blue'}
                  loading={true}
                  size={65}
                  aria-label="Loading Spinner"
                />
                <div>Uploading images...</div>
              </div>
          ) :(
            <div className="base-container captured-images-container">
              <div className="captured-images-list">
                {capturedImages.map((capturedImage, index) => (
                  <img
                    src={capturedImage}
                    alt={'Input Image'}
                  />
                ))}
              </div>
                <div className="upload-container">
                  <button className="twovatar-button" onClick={uploadImages}>Upload images</button>
                  <button className="twovatar-button" onClick={resetState}>Retake images</button>
                </div>
            </div>
          )}
        </div>
      )}

      {currentState === captureImageStates.uploadSuccess && (
        <div className="uploade-success-container">
          Upload success!
          <Link className="menu-text" to='/home'>
            <button className="twovatar-button">Return home</button>
          </Link>
        </div>
      )}
    </div>
  );
};

export default WebcamCapture;