import { Link } from "react-router-dom"
import avatars from '../../images/avatars.png';
import './index.css';

const Home = () => {
  return (
    <div className="home-body">
      <div className="description-container">
        2vatar lets you convert yourself to a 3d emoji.
        <br />
        <br />
        <br />
        <Link className="menu-text" to='/demo'>
          <button className="twovatar-button" >Try Demo</button>
        </Link>
      </div>
      <div className="image-container">
        <img src={avatars} className="avatar-image" alt="avatar-image" />
      </div>
    </div>
  );
};

export default Home;